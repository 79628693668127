// @ts-nocheck
import { FunctionComponent } from "react";
import {
  Container, Inner, Heading, Column, ButtonLink, Statement,
} from "../UI";
// @ts-ignore
import img from "../../public/static/images/online.svg";

// eslint-disable-next-line max-len
const msgMissionStatement = "I help students improve their grades and confidence by instilling in them a lifelong passion for learning";
const msgLetsConnect = "Let's Connect!";
// eslint-disable-next-line max-len
const msgAboutMe = "Hello! My name is Chaarvee Sood and I am a high school science teacher. I also tutor students on the side to help them better understand the material and reach their academic goals. I love teaching and I enjoy helping students learn. I am patient and I like to find different ways to explain things so that students can understand. I am also friendly and I like to get to know my students. I believe that every student has the potential to do well in school and I am here to help them.";

const Home: FunctionComponent = () => (
  <Container id="Home">
    <Inner margin="5vh 11vw">
      <Column width="50%" alignSelf="center" alignChildren="center">
        <Heading>{ msgMissionStatement }</Heading>
        <Statement>{ msgAboutMe }</Statement>
        <ButtonLink
          onClick={() => document.getElementById("Booking").scrollIntoView()}
          to="/#Booking"
        >
          { msgLetsConnect }
        </ButtonLink>
      </Column>
      <Column width="35%" alignSelf="center">
        <img src={img} alt="Tutoring through zoom" width="100%" />
      </Column>
    </Inner>
  </Container>
);

export default Home;
