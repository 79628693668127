// @ts-nocheck
import { FunctionComponent } from "react";
import msg from "../Text/msg.json";
import {
  Subheading,
  Container,
  Inner,
  Column,
  Row,
  Card,
} from "../UI";

import { CalendlyButton } from "../UI/buttons";

const bookingSteps = [
  {
    heading: "1. Schedule Consultation",
    imgSrc: "bio",
    background: "#f7f4ed",
    message: msg.booking.stepOne,
  },
  {
    heading: "2. Book Your First Lesson",
    imgSrc: "bio",
    background: "#f7f4ed",
    message: msg.booking.stepTwo,
  },
  {
    heading: "3. Something goes here",
    imgSrc: "bio",
    background: "#f7f4ed",
    // eslint-disable-next-line max-len
    message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce condimentum consequat interdum. Quisque et nulla condimentum, vehicula sapien eget, aliquam nibh.",
  },
];

const Booking: FunctionComponent = () => (
  <Container id="Booking" background="#fff">
    <Inner margin="3vw 2vw">
      <Subheading>How it works</Subheading>
      <Column width="90vw">
        <Row>
          { bookingSteps.map(({
            heading, imgSrc, background, message,
          }) => (
            <Card
              key={heading}
              heading={heading}
              message={message}
              background={background}
              imgSrc={imgSrc}
            />
          ))}
        </Row>
      </Column>
      <CalendlyButton />
    </Inner>
  </Container>
);

export default Booking;
