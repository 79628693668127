// @ts-nocheck
import {
  BrowserRouter,
} from "react-router-dom";

import {
  Home, NavBar, Subjects, TeachingApproach, Booking,
} from ".";

function App() {
  const navBarItems = [
    {
      label: "Home",
      address: "/",
      ref: "Home",
    },
    {
      label: "Subjects",
      address: "/#Subjects",
      ref: "Subjects",
    },
    {
      label: "Teaching Approach",
      address: "/#Teaching-Approach",
      ref: "Teaching-Approach",
    },
    {
      label: "How It Works",
      address: "/#Booking",
      ref: "Booking",
    },
  ];

  // @ts-ignore
  return (
    <BrowserRouter>
      <NavBar items={navBarItems} />
      <Home />
      <Subjects />
      <TeachingApproach />
      <Booking />
    </BrowserRouter>
  );
}

export default App;
