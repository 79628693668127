import { FunctionComponent } from "react";
import styled from "styled-components";
import msg from "../Text/msg.json";
import {
  Subheading, Container, Inner, Column, Row, CardList, Header,
} from "../UI";

// @ts-ignore
import science from "../../public/static/images/science.svg";
// @ts-ignore
import math from "../../public/static/images/math.svg";
// @ts-ignore
import chemistry from "../../public/static/images/chemistry.svg";
// @ts-ignore
import biology from "../../public/static/images/biology.svg";

const classes = [
  {
    heading: "Science",
    imgSrc: science,
    background: "#f7f4ed",
    list: msg.subjects.science,
  },
  {
    heading: "Mathematics",
    imgSrc: math,
    background: "#f7f4ed",
    list: msg.subjects.mathematics,
  },
  {
    heading: "Biology",
    imgSrc: biology,
    background: "#f7f4ed",
    // eslint-disable-next-line max-len
    list: msg.subjects.biology,
  },
  {
    heading: "Chemistry",
    imgSrc: chemistry,
    background: "#f7f4ed",
    // eslint-disable-next-line max-len
    list: msg.subjects.chemistry,
  },
];

const Subjects: FunctionComponent = () => (
  <Container id="Subjects" background="#fff">
    <Inner margin="3vw 2vw">
      <Subheading>What I teach / Subjects taught</Subheading>
      <Column width="96vw">
        <Row>
          <Header letterSpacing="0.2em">Junior (Years 3 - 10)</Header>
          <Header letterSpacing="0.2em">Senior (Years 11 & 12)</Header>
        </Row>
        <Row>
          { classes.map(({
            heading, imgSrc, background, list,
          }) => (
            <CardList
              heading={heading}
              list={list}
              background={background}
              imgSrc={imgSrc}
            />
          ))}
        </Row>
      </Column>
    </Inner>
  </Container>
);

export default Subjects;
