import { Home } from "./Home";
import { NavBar } from "./NavBar";
import { Subjects } from "./Subjects";
import { TeachingApproach } from "./TeachingApproach";
import { Booking } from "./Booking";

export {
  Home,
  NavBar,
  Subjects,
  TeachingApproach,
  Booking,
};
