// @ts-nocheck
import { FunctionComponent } from "react";
import styled from "styled-components";
import { Container, Inner, Link } from "../UI";
import { Calendly } from "../UI/buttons";

const Title = styled.h1`
  color: #000;
  font-weight: 600;
  font-size: 0.8vw;
  text-transform: uppercase;
  letter-spacing: 0.16rem;
  font-family: monospace;
`;

const NavLink = styled(Link)`
  margin: 0 1rem;
`;

const BookingButton = styled(Calendly)`
  margin-left: 5rem;
`;

const NavBar: FunctionComponent = ({ items }) => (
  <Container height="8vh">
    <Inner margin="0 4.5vw">
      <div>
        <Title>Like Minded Tutoring</Title>
      </div>
      <div>
        { items.map(({ label, address, ref }) => (
          <NavLink
            key={label}
            onClick={() => document.getElementById(ref).scrollIntoView()}
            to={address}
          >
            { label }
          </NavLink>
        ))}
        <BookingButton
          url="https://calendly.com/likemindedtutoring"
          text="Book a Consultation"
          rootElement={document.getElementById("root")}
        />
      </div>
    </Inner>
  </Container>
);

export default NavBar;
