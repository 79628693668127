import { FunctionComponent } from "react";
import styled from "styled-components";
import { Text, Container, Header } from "../UI";

interface CardData {
  heading: string,
  imgSrc: string,
  message: string,
  background: string,
}

interface CardListData {
  heading: string,
  imgSrc: string,
  list: string[],
  background: string,
}

const Image = styled.img`
  border-radius: 25%;
  border: 5px solid black;
`;

const Block = styled.div`
  margin: 2vw 2vw;
  text-align:center;
  height: 100%;
`;

const List = styled.ul`
    font-size: 1.1vw;
    line-height: 1.75;
    font-weight: 300;
    text-align: left;
    white-space: pre-line;
`;

const Card: FunctionComponent<CardData> = ({
  heading,
  imgSrc,
  background,
  message,
}: CardData) => (
  <Container background={background} margin="0.5vw 2vw 2vw">
    <Block>
      <Image src={imgSrc} width="30%" />
      <Header>{ heading }</Header>
      <Text>{ message }</Text>
    </Block>
  </Container>
);

const CardList: FunctionComponent<CardListData> = ({
  heading,
  imgSrc,
  list,
  background,
}: CardListData) => (
  <Container background={background} margin="0.5vw 2vw 2vw">
    <Block>
      <Image src={imgSrc} width="30%" />
      <Header>{ heading }</Header>
      <List>
        { list.map((item) => <li key={item}>{ item}</li>)}
      </List>
    </Block>
  </Container>
);

export {
  Card,
  CardList,
};
