import { Link } from "react-router-dom";
import styled from "styled-components";
import { Card, CardList } from "./card";

const StyledLink = styled(Link)`
  font-size: 14px;
  color: #000;
  text-decoration: none;
  font-family: monospace;
  font-weight: 600;
`;

const ButtonLink = styled(StyledLink)`
  border-radius: 0.5rem;
  padding: 1rem 2.5rem;
  background-color: black;
  color: #fff;
  margin: 1vh auto;
`;

const Container = styled("div")<{
  height?: string,
  background?: string,
  margin?: string
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: ${(props) => props.height};
  background-color: ${(props) => props.background};
  width: 100vw;
  margin: ${(props) => props.margin};
`;

const Inner = styled("div")<{ margin?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: ${(props) => props.margin};
  height: 100%;
`;

const Column = styled("div")<{
  width: string,
  alignSelf?: string,
  alignChildren?: string
}>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.alignChildren};
  align-self: ${(props) => props.alignSelf};
  width: ${(props) => props.width};
  margin: auto;
`;

const Row = styled("div")`
  display: flex;
  min-height: 4.5vw;
  width: 100%;
  justify-content: space-around;
`;

const Heading = styled.h1`
  color: #000;
  font-size: 3.5vw;
  line-height: 1.4;
  font-weight: 500;
  margin: 2vw auto;
  text-align: center;
`;

const Subheading = styled.h3`
  color: #000;
  font-size: 1.6vw;
  line-height: 1;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.4vw;
  margin: 1em auto;
  text-align: center;
  width: 100%;
`;

const Header = styled("h4")<{ letterSpacing?: string, alignment?: string }>`
  color: #000;
  margin-bottom: 0;
  font-size: 1.2vw;
  font-weight: 600;
  line-height: 1;
  text-align: ${(props) => props.alignment ?? "center"};
  letter-spacing: ${(props) => props.letterSpacing ?? "0.05em"};
`;

const Statement = styled.p`
  font-size: 1.3vw;
  font-weight: 400;
  font-style: italic;
  line-height: 1.5;
  text-align: center;
  margin: 1vw auto 1.5vw;
`;

const Text = styled("p")<{ alignment?: string }>`
  font-size: 1.1vw;
  line-height: 1.75;
  font-weight: 300;
  text-align: ${(props) => props.alignment ?? "center"};
  white-space: pre-line;
`;

export {
  StyledLink as Link,
  ButtonLink,
  Container,
  Inner,
  Column,
  Row,
  Heading,
  Subheading,
  Header,
  Statement,
  Text,
  Card,
  CardList,
};
